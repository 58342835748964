import { useMemo } from "react";

function useSkeleton({ data, loading, limit }) {
  return useMemo(
    () => (!loading ? data.slice(0, limit) : Array.from({ length: limit })),
    [data, limit, loading]
  );
}

export default useSkeleton;
