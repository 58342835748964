import { Icon, Stack, Typography } from "components";
import classnames from "classnames";
import PropTypes from "prop-types";

function Group({ className, children, icon, title, ...props }) {
  return (
    <Stack
      className={classnames("BupStack-group", className)}
      spacing={{ xs: 2, sm: 2.5 }}
      {...props}
    >
      <Typography variant="h2">
        {title} {icon && <Icon name={icon} />}
      </Typography>
      {children}
    </Stack>
  );
}

Group.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Group;
