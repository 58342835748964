import classnames from "classnames";
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

const CustomAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: `${theme.palette.premium.main}33`,
  color: theme.palette.premium.contrastText,
}));

function PremiumAlert({ className, children, ...props }) {
  return (
    <CustomAlert
      action={
        props.onClose && (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={props.handleClose}
            title="Cerrar"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
      }
      className={classnames("BupAlert-root Bup-premium", className)}
      severity="info"
      sx={{ mb: 2 }}
      variant={props.variant}
    >
      {props.title && <AlertTitle>{props.title}</AlertTitle>}
      {children}
    </CustomAlert>
  );
}

PremiumAlert.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  handleClose: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default PremiumAlert;
