import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import PropTypes from "prop-types";

function BupDrawer({ children, options }) {
  return (
    <SwipeableDrawer
      anchor="bottom"
      className="BupDrawer-root"
      disableSwipeToOpen={false}
      onClose={options.handleClose}
      onOpen={options.handleClick}
      open={options.open}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div className="BupDrawer-puller" />
      <Box
        className="BupDrawer-content"
        component="ul"
        onClick={options.handleClose}
      >
        {children}
      </Box>
    </SwipeableDrawer>
  );
}

BupDrawer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BupDrawer;
