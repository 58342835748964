import classnames from "classnames";
import Fragment from "components/system/Fragment";
import Icon from "components/display/Icon";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

function BupIconButton({
  className,
  onClick,
  size = "medium",
  variant,
  ...props
}) {
  if (props.hidden) return null;

  const Alert = props.badge ? Badge : Fragment;
  const Wrapper = props.title && !props.disabled ? Tooltip : Fragment;

  return (
    <Wrapper
      arrow={props.arrow}
      placement={props.placement}
      title={props.title}
    >
      <IconButton
        id={props.id}
        className={classnames("BupButton-root BupButton-icon", className, {
          "BupButton-circle": props.circle,
          "BupButton-square": props.square,
          "BupButton-contained": variant === "contained",
          "BupButton-outlined": variant === "outlined",
          "Bup-active": props.active,
          "Bup-selected": props.selected,
          "Bup-disabled": props.disabled,
          "Bup-loading": props.loading,
        })}
        color={props.color}
        disabled={props.disabled}
        {...{ onClick, size }}
      >
        <Alert badgeContent={props.badge} color="error" max={9}>
          <Icon name={props.icon} variant={props.iconVariant} />
        </Alert>
      </IconButton>
    </Wrapper>
  );
}

BupIconButton.propTypes = {
  active: PropTypes.bool,
  arrow: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconVariant: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  placement: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  ...IconButton.propTypes,
};

export default BupIconButton;
