import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Icon, Link, Typography } from "components";
import classnames from "classnames";
import PropTypes from "prop-types";
import { getById } from "utils";

function Empty({ disabled, ...props }) {
  if (props.list?.length || props.hidden) return null;

  if (props.loading) {
    return (
      <Box className="BupEmpty-root">
        <CircularProgress />
      </Box>
    );
  }

  const handleClick = () => {
    if (props.refId) getById(props.refId).click();
    else if (props.onClick) props.onClick();
  };

  return (
    <Box
      className={classnames("BupEmpty-root", {
        xl: props.notFound,
        "BupEmpty-inset": props.inset,
      })}
    >
      <Icon name={props.icon} circle />
      {props.title && <Typography variant="h6">{props.title}</Typography>}
      <Typography variant="span">{props.alert}</Typography>
      {props.link &&
        (props.to ? (
          <Link disabled={disabled} onClick={handleClick} to={props.to}>
            {props.link}
          </Link>
        ) : (
          <Button
            color="primary"
            disabled={disabled}
            onClick={handleClick}
            variant="outlined"
          >
            {props.link}
          </Button>
        ))}
    </Box>
  );
}

Empty.propTypes = {
  alert: PropTypes.string,
  button: PropTypes.bool,
  icon: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
};

export default Empty;
