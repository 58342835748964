import { useState, useEffect, useRef } from "react";

function useShowMore(text) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (textRef.current) {
      const height = textRef.current.clientHeight;
      setShowButton(height > 90);
    }
  }, [text]);

  return {
    isExpanded,
    showButton,
    textRef,
    toggleExpanded,
  };
}

export default useShowMore;
