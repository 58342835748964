import { useNetwork } from "contexts";
import { useLazyQuery, useMutation } from "gql";
import { GET_USER_PHOTO } from "mutations/account";
import { GET_GROUP_PICTURE } from "queries/community";
import { GET_EVENT_PICTURE } from "queries/events";
import { useEffect, useState } from "react";
import { updatePictures } from "store";

function usePhotoUrl(type, id) {
  const [getPhoto, { data, loading }] = useMutation(GET_USER_PHOTO);
  const [getEventPhoto, { data: event, loading: eLoading }] =
    useLazyQuery(GET_EVENT_PICTURE);
  const [getGroupPhoto, { data: group, loading: gLoading }] =
    useLazyQuery(GET_GROUP_PICTURE);

  const [photoUrl, setPhotoUrl] = useState(null);
  const { pictures } = useNetwork();

  useEffect(() => {
    if (!photoUrl) {
      if (type === "user") {
        const ids = [];

        if (pictures[id]) {
          setPhotoUrl(pictures[id]);
        } else {
          ids.push(id);
        }
        if (ids.length) {
          getPhoto({ ids });
        }
      } else if (type === "event") {
        getEventPhoto({ id });
      } else if (type === "group") {
        getGroupPhoto({ id });
      }
    }
  }, [type, id]);

  useEffect(() => {
    if (data && !loading) {
      const pics = Object.keys(data);

      if (pics.length) {
        pics.forEach((id) => {
          setPhotoUrl(data[id]);
          updatePictures(data);
        });
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (event._id && !eLoading)
      setPhotoUrl(event.photoUrl || event.config?.photoUrl);
  }, [event, eLoading]);

  useEffect(() => {
    if (group._id && !gLoading) {
      setPhotoUrl(group.info?.photoUrl);
    }
  }, [group, gLoading]);

  return photoUrl;
}

export default usePhotoUrl;
