import { useAuth } from "contexts";
import { useFormik } from "hooks";
import { useMutation } from "gql";
import { CREATE_POST } from "mutations/community";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import helper from "utils/helper";

function useCreatePost({ disabled, discussion, refId, target }) {
  const [createPost, { data, loading }] = useMutation(CREATE_POST);
  const [attach, setAttach] = useState(0);
  const [open, setOpen] = useState(false);
  const { uid, user } = useAuth();

  const form = useFormik({ initialValues: { message: "" } });
  const input = useRef(null);

  const handleCreate = () => {
    if (loading || disabled || !uid) return;

    const content = {
      message: form.values.message.trim(),
    };

    if (content.message.length) {
      if (content.message.includes("<script>")) return;

      if (user.hasFullAccess) {
        content.message = helper.formatLinksInText(content.message);

        if (attach !== 0) {
          const { event, image, video } = form.values;

          if (attach === 1 && image) {
            if (helper.validateImageUrl(image)) content.photoUrl = image;
          } else if (attach === 2 && video) {
            const videoUrl = helper.convertToEmbedLink(video);
            if (videoUrl) content.videoUrl = videoUrl;
          } else if (attach === 3 && event) {
            if (helper.validateEventUrl(event)) content.eventUrl = event;
          }
        }
      }

      createPost({
        from: uid,
        content,
        target,
        to: refId,
      });

      toast.success("Se ha publicado en el evento.");
      handleReset();
    }
  };

  const handleReset = () => {
    form.resetForm();
    input.current.value = "";
    setOpen(false);
    setAttach(0);
  };

  useEffect(() => {
    if (!loading && data._id) {
      const { posts, total } = discussion.values;
      discussion.setValues({
        total: total + 1,
        posts: posts[0]?.pinned
          ? [posts[0], data, ...posts.slice(1)]
          : [data, ...posts],
      });
    }
  }, [data, loading, CREATE_POST]);

  useEffect(() => {
    if (open) {
      setTimeout(() => input.current?.focus(), 100);
    }
  }, [open]);

  return {
    attach,
    createPost,
    form,
    handleCreate,
    handleReset,
    input,
    loading,
    open,
    setOpen,
    setAttach,
  };
}

export default useCreatePost;
