import Paper from "@mui/material/Paper";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupPaper({ children, className, ...props }) {
  return (
    <Paper
      className={classnames("BupPaper-root", className)}
      {...defaultProps}
      {...props}
    >
      {children}
    </Paper>
  );
}

const defaultProps = {
  elevation: 0,
  square: false,
  variant: "elevation",
};

BupPaper.propTypes = {
  ...Paper.propTypes,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  elevation: PropTypes.number,
  square: PropTypes.bool,
  variant: PropTypes.oneOf(["elevation", "outlined"]),
};

export default BupPaper;
