import classnames from "classnames";
import Box from "components/layout/Box";
import Button from "components/inputs/Button";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useRouter } from "routes";

function BupLink({ className, disabled, params, to, ...props }) {
  const router = useRouter();

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      router.push(to, params);
      window.scrollTo(0, 0);
    },
    [params, to, router]
  );

  if (props.hidden) return null;

  if (to) {
    const Component = params || props.box ? Box : Button;
    const path = Array.isArray(to) ? to[0] : to;

    return (
      <Component
        className={classnames("BupLink-root BupLink-nav", className, {
          "Bup-active": props.navLink && router.isActive(path),
          "Bup-inline": props.inline,
          "Bup-disabled": disabled,
        })}
        id={props.id}
        color={props.color}
        onClick={handleClick}
        variant="text"
      >
        {props.children}
      </Component>
    );
  }

  return (
    <a
      className={classnames("BupLink-root BupLink-href", className, {
        "Bup-disabled": disabled,
      })}
      id={props.id}
      href={props.href}
      onClick={props.onClick}
      target={props.target || "_blank"}
    >
      {props.children}
    </a>
  );
}

BupLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  params: PropTypes.object,
  target: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default BupLink;
