import { useConsole } from "contexts";
import { useRouter } from "routes";

function useRouterTab() {
  const { values } = useConsole().cpanel;
  const router = useRouter();

  const active = parseInt(router.tab || values.tab);
  const setActive = router.setTab;

  return [active, setActive];
}

export default useRouterTab;
