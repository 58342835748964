import Box from "@mui/material/Box";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupBox({ children, className, disabled, hidden, ...props }) {
  if (hidden) return null;

  delete props["loading"];

  return (
    <Box
      className={classnames("BupBox-root", className, {
        "Bup-disabled": disabled,
      })}
      {...props}
    >
      {children}
    </Box>
  );
}

BupBox.propTypes = {
  ...Box.propTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  hidden: PropTypes.bool,
};

export default BupBox;
