import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import classnames from "classnames";

function Background({ children, className, src, url, ...props }) {
  const [source, setSource] = useState("");

  useEffect(() => {
    const image = new Image();
    image.src = url || "/" + src;
    image.onload = () => setSource(image.src);
  }, [src, url]);

  return (
    <Box
      className={classnames("BupImage-lazy", className)}
      style={{ backgroundImage: `url(${source})` }}
      {...props}
    >
      {children}
    </Box>
  );
}

Background.propTypes = {
  ...Box.propTypes,
  children: PropTypes.any,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default Background;
