import { gql } from "@apollo/client";
import { POST_FIELDS } from "fragments";

const CREATE_POST = gql`
  mutation CreatePost(
    $target: String!
    $from: ID!
    $to: ID!
    $content: createPostContentInput!
  ) {
    createPost(target: $target, from: $from, to: $to, content: $content) {
      ...PostFields
    }
  }
  ${POST_FIELDS}
`;

export default CREATE_POST;
