import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";

import classnames from "classnames";
import { useConsole } from "contexts";
import PropTypes from "prop-types";
import { useMemo } from "react";

function BupAvatar({ className, loading, src, online, ...props }) {
  const { darkMode } = useConsole();

  return useMemo(() => {
    if (loading) {
      return (
        <Skeleton
          className={classnames("BupAvatar-root BupAvatar-skeleton", className)}
          variant="circular"
        />
      );
    }

    if (!online) {
      return (
        <Avatar
          className={classnames("BupAvatar-root", className)}
          src={src}
          {...props}
        />
      );
    }

    const StyledBadge = styled(Badge)(({ theme }) => ({
      "& .MuiBadge-badge": {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.main,
        boxShadow: `0 0 0 2px ${
          !darkMode.isDark ? theme.palette.background.paper : "#101418"
        }`,
        "&::after": {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          animation: "ripple 1.2s infinite ease-in-out",
          border: "1px solid currentColor",
          content: '""',
        },
      },
      "@keyframes ripple": {
        "0%": {
          transform: "scale(.8)",
          opacity: 1,
        },
        "100%": {
          transform: "scale(2.4)",
          opacity: 0,
        },
      },
    }));

    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <Avatar
          className={classnames("BupAvatar-root", className)}
          src={src}
          {...props}
        />
      </StyledBadge>
    );
  }, [loading, src]);
}

Avatar.propTypes = {
  ...Avatar.propTypes,
  className: PropTypes.string,
  loading: PropTypes.bool,
  src: PropTypes.string,
  online: PropTypes.bool,
};

export default BupAvatar;
