import { useAuth } from "contexts";
import { CREATE_COMMENT } from "mutations/community";
import { useMutation } from "gql";
import { useEffect, useRef, useState } from "react";

function useCreateComment({ disabled, post, ...props }) {
  const [createComment, { data, loading }] = useMutation(CREATE_COMMENT);
  const [message, setMessage] = useState("");
  const input = useRef(null);
  const { uid } = useAuth();

  const handlePost = () => {
    if (loading || !uid) return;

    const copy = message.trim();

    if (copy.length > 0) {
      createComment({
        from: uid,
        message: copy,
        id: post._id,
      });

      setMessage("");
      input.current.value = "";
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handlePost();
    }
  };

  useEffect(() => {
    if (!loading && data.comments) {
      props.setComments(data.comments);
      props.setCreate(false);
    }
  }, [data, loading, CREATE_COMMENT]);

  useEffect(() => {
    props.setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!disabled) {
      input.current?.focus();
    }
  }, [disabled]);

  return {
    handlePost,
    onKeyPress,
    input,
    message,
    setMessage,
  };
}

export default useCreateComment;
