import classnames from "classnames";
import PropTypes from "prop-types";

function BupVideo({ className, src }) {
  if (!src) return null;

  return <iframe className={classnames("Bup-video", className)} src={src} />;
}

BupVideo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};

export default BupVideo;
