import { gql } from "@apollo/client";

const FRIEND_SEND_REQUEST = gql`
  mutation SendFriendRequest($from: ID!, $to: ID!, $action: String!) {
    sendFriendRequest(from: $from, to: $to, action: $action) {
      _id
    }
  }
`;

export default FRIEND_SEND_REQUEST;
