import { useAuth } from "contexts";
import { useMemo } from "react";

function useLoader(called, loading, response, target) {
  const { disabled } = useAuth();

  return useMemo(
    () =>
      !disabled &&
      (((called || response?.length) && !loading) || !target?.length),
    [disabled, called, loading, response?.length, target?.length]
  );
}

export default useLoader;
