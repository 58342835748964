import { gql } from "@apollo/client";

const FRIEND_RESOLVE_REQUEST = gql`
  mutation ResolvefriendRequest($from: ID!, $to: ID!, $action: String!) {
    resolvefriendRequest(from: $from, to: $to, action: $action) {
      _id
    }
  }
`;

export default FRIEND_RESOLVE_REQUEST;
