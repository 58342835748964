import CREATE_COMMENT from "./common/createComment";
import CREATE_POST from "./common/createPost";
import DELETE_POST from "./common/deletePost";
import UPDATE_COMMENT_LIKES from "./common/updateCommentLikes";
import UPDATE_POST_LIKES from "./common/updatePostLikes";
import UPDATE_POST_PIN from "./common/updatePostPin";

export * from "./friends";
export * from "./groups";

export {
  CREATE_COMMENT,
  CREATE_POST,
  DELETE_POST,
  UPDATE_COMMENT_LIKES,
  UPDATE_POST_LIKES,
  UPDATE_POST_PIN,
};
