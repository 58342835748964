import classnames from "classnames";
import Stack from "components/layout/Stack";
import Typography from "components/display/Typography";
import PropTypes from "prop-types";

function Form({ className, children, form, title, titleVariant, ...props }) {
  return (
    !props.hidden && (
      <form
        className={classnames("BupForm-root", className)}
        onSubmit={form.handleSubmit}
        onReset={form.handleReset}
        {...props}
      >
        {title && (
          <Typography className="BupForm-title" variant={titleVariant || "h1"}>
            {title}
          </Typography>
        )}
        <Stack spacing={2}>{children}</Stack>
      </form>
    )
  );
}

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  form: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default Form;
