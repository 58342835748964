import { gql } from "@apollo/client";

const UPDATE_POST_LIKES = gql`
  mutation UpdatePostLikes($userProfileId: ID!, $action: String!, $id: ID!) {
    likePost(userProfileId: $userProfileId, action: $action, _id: $id) {
      likes {
        _id
      }
    }
  }
`;

export default UPDATE_POST_LIKES;
