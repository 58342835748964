const pages = [
  {
    name: "events",
    href: "/overview",
    icon: "calendar_month",
    title: "Eventos",
    routes: {
      overview: ["Novedades", "newspaper", "/overview", true],
      events: ["Mis eventos", "event_note", "/events", true],
      eventSuggest: ["Sugeridos", "assistant", "/events/suggest", true],
      eventExplore: ["Explorar", "explore", "/events/explore", true],
      event: ["Evento", "event_available", "/event/:eid", 0],
      eventCalendar: ["Calendario", "calendar_month", "/events/calendar", 0],
      eventHistory: ["Historial", "history", "/events/history", 0],
    },
  },
  {
    name: "community",
    href: "/groups",
    icon: "people",
    title: "Comunidad",
    routes: {
      groups: ["Mis grupos", "workspaces", "/groups", true],
      friends: ["Mis amigos", "people_alt", "/friends", true],
      feed: ["Publicaciones", "feed", "/feed", true],
      friend: ["Contacto", "perm_contact_calendar", "/user/:uid", 0],
      friendBirthdays: ["Cumpleaños", "cake", "/friends/birthdays", 0],
      friendList: ["Contactos", "book", "/friends/network", 0],
      friendRequests: ["Solicitudes", "mail_outline", "/friends/requests", 0],
      friendSuggests: ["Sugerencias", "assistant", "/friends/suggests", 0],
      group: ["Grupo", "group_work", "/group/:gid", 0],
    },
  },
  {
    name: "benefits",
    href: "/benefits",
    icon: "auto_awesome",
    title: "Beneficios",
    routes: {
      benefits: ["Salud", "health_and_safety", "/benefits", true],
    },
  },
  {
    name: "account",
    href: "/profile",
    hidden: true,
    icon: "account_circle",
    title: "Cuenta",
    routes: {
      profile: ["Mi perfil", "account_circle", "/profile", true],
      subscription: ["Suscripción", "credit_card", "/subscription", true],
      interests: ["Intereses", "interests", "/profile/interests", true],
      messages: ["Mensajes", "chat", "/messages", 0],
      notifications: ["Notificaciones", "notifications", "/notifications", 0],
      points: ["Mis puntos", "paid", "/profile/points", 0],
      settings: ["Ajustes", "settings", "/settings", 0],
      help: ["Ayuda", "help", "/help", 0],
    },
  },
];

export default pages;
