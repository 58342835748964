import Menu from "@mui/material/Menu";
import { Drawer } from "components";
import { useConsole } from "contexts";
import PropTypes from "prop-types";

function BupMenu({ children, options }) {
  const { screen } = useConsole();

  if (!screen.mobile) {
    return (
      <Menu
        anchorEl={options.anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="BupMenu-root"
        elevation={0}
        id="event-menu"
        onClick={options.handleClose}
        onClose={options.handleClose}
        open={options.open}
        sx={{ mt: 1.5 }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {children}
      </Menu>
    );
  }
  return <Drawer options={options}>{children}</Drawer>;
}

BupMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BupMenu;
