import { gql } from "@apollo/client";

const UPDATE_COMMENT_LIKES = gql`
  mutation UpdateCommentLikes(
    $userProfileId: ID!
    $action: String!
    $id: ID!
    $commentId: ID!
  ) {
    likeComment(
      userProfileId: $userProfileId
      action: $action
      _id: $id
      commentId: $commentId
    ) {
      likes {
        _id
      }
    }
  }
`;

export default UPDATE_COMMENT_LIKES;
