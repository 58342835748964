import { gql } from "@apollo/client";

const UPDATE_POST_PIN = gql`
  mutation UpdatePostPin($id: ID!, $pinned: Boolean) {
    modifyPost(_id: $id, pinned: $pinned) {
      _id
    }
  }
`;

export default UPDATE_POST_PIN;
