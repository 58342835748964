import LinearProgress from "@mui/material/LinearProgress";
import { Button, Stack, Typography } from "components";
import PropTypes from "prop-types";

function LazyLoad({
  current = 0,
  errors = 0,
  hidden,
  loading,
  onLoadMore,
  text,
  total = 0,
}) {
  const displayedCount = Math.min(current + errors, total);
  const progress = (100 * displayedCount) / total;

  const handleLoadMore = () => {
    if (onLoadMore) {
      onLoadMore();
    }
  };

  if (displayedCount >= total || hidden) {
    return null;
  }

  return (
    <Stack alignItems="center" className="BupLazy-load" spacing={2}>
      <LinearProgress
        className="BupLazy-progress"
        color="inherit"
        variant="determinate"
        value={progress}
      />
      <Button
        color="inherit"
        elevation={0}
        loading={loading}
        onClick={handleLoadMore}
        variant="outlined"
      >
        {!loading ? `Mostrar más ${text}` : "Cargando..."}
      </Button>
      <Typography variant="span">
        Mostrando {displayedCount} de {total} resultados &mdash;{" "}
        {Math.floor(progress)}%
      </Typography>
    </Stack>
  );
}

LazyLoad.propTypes = {
  current: PropTypes.number.isRequired,
  errors: PropTypes.number,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  text: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

export default LazyLoad;
