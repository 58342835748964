import Stack from "@mui/material/Stack";
import classnames from "classnames";
import PropTypes from "prop-types";
import { GRID } from "consts";

function BupStack({ children, className, hidden, wrapper, ...props }) {
  if (hidden) return null;

  return (
    <Stack
      className={classnames("BupStack-root", className)}
      spacing={wrapper ? GRID.spacing : 0}
      {...props}
    >
      {children}
    </Stack>
  );
}

BupStack.propTypes = {
  ...Stack.propTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  hidden: PropTypes.bool,
};

export default BupStack;
