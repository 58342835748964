import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupChip({ className, height, width, ...props }) {
  if (props.hidden) return null;

  return !props.loading ? (
    <Chip
      className={classnames("BupChip-root", className, {
        "Bup-active": props.query === props.label,
      })}
      {...props}
    />
  ) : (
    <Skeleton {...{ height, width }} variant="rounded" />
  );
}

BupChip.propTypes = {
  ...Chip.propTypes,
  className: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BupChip;
