import { gql } from "@apollo/client";

const LEAVE_GROUP = gql`
  mutation LeaveGroup($id: ID!, $groupId: ID!) {
    leaveGroup(_id: $id, groupId: $groupId) {
      groups {
        joined {
          _id
        }
      }
    }
  }
`;

export default LEAVE_GROUP;
