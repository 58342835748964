import classnames from "classnames";
import { Icon } from "components";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useRouterTab } from "hooks/common";
import { Tab, Tabs } from "@mui/material";

function BupTabs({ className, hidden, options, setValue, value, variant }) {
  const [active, setActive] = useRouterTab();

  useEffect(() => {
    if (typeof setValue === "function") {
      setValue(options[0].value);
    }
  }, [options, setValue]);

  if (hidden) return null;

  const val = value !== undefined ? value : active;

  const handleChange = (_, newValue) => {
    if (typeof setValue !== "function") {
      setActive(newValue);
    } else {
      setValue(newValue);
    }
  };

  return (
    <Tabs
      allowScrollButtonsMobile
      className={classnames("BupTabs-root", className)}
      onChange={handleChange}
      value={val}
      variant={variant || "scrollable"}
      scrollButtons
    >
      {options.map((option) => (
        <Tab
          key={option.value}
          className={classnames({ "Bup-active": val === option.value })}
          icon={option.icon && <Icon name={option.icon} />}
          label={option.label}
          value={option.value}
        />
      ))}
    </Tabs>
  );
}

BupTabs.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool,
  options: PropTypes.array.isRequired,
  setValue: PropTypes.func,
  value: PropTypes.any,
  variant: PropTypes.oneOf(["standard", "scrollable", "fullWidth"]),
};

export default BupTabs;
